import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/scoutgame.xyz/scoutgame.xyz/node_modules/@mui/material/Box/Box.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/scoutgame.xyz/scoutgame.xyz/node_modules/@mui/material/Button/Button.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/scoutgame.xyz/scoutgame.xyz/node_modules/@mui/material/Container/Container.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/scoutgame.xyz/scoutgame.xyz/node_modules/@mui/material/Stack/Stack.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/scoutgame.xyz/scoutgame.xyz/node_modules/@mui/material/Typography/Typography.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/scoutgame.xyz/scoutgame.xyz/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/scoutgame.xyz/scoutgame.xyz/node_modules/next/dist/client/link.js");
